import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import classes from './header.module.css'
import aside from './aside.module.css'
import Logo from '../../assets/images/logo.png'
import { Link, useLocation } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { CustomerService } from '../../api/CustomerService'
import Search from '../../assets/images/search.svg'
import Menu from '../../assets/images/menu.svg'
import { IItem } from '../../interfaces/Item'
import { ProductService } from '../../api/ProductService'
import { ItemsGrid } from '../index'
import ProfileButton from './profileButton'

interface IHeader {
    transparent: boolean
}

const Header: React.FC<IHeader> = ({ transparent }) => {
    const productsService = new ProductService()

    const [offset, setOffset] = useState(0)
    const [prevOffset, setPrevOffset] = useState(0)
    const [showLinks, setShowLinks] = useState(true)
    const [hamIsActive, setHamIsActive] = useState(false)
    const [searchIsActive, setSearchIsActive] = useState(false)
    const [balance, setBalance] = useState(0)
    const [searchQuery, setSearchQuery] = useState('')
    const [searchResults, setSearchResults] = useState<IItem[] | null>(null)
    // const { user } = useTypedSelector((state) => state.userReducer)
    const [hits, setHits] = useState<IItem[]>([])
    const [isAuth, setIsAuth] = useState(false)

    const productService = new ProductService()

    useEffect(() => {
        let isMount = true
        const getBalance = async () => {
            try {
                const api = new CustomerService()
                const _balance = await api.getBalance()
                if (isMount) setBalance(_balance)
                setIsAuth(true)
            } catch (e) {
                setIsAuth(false)
            }
        }
        getBalance().then()

        productsService
            .getProductsByBadge('hit')
            .then(({ values }) => {
                setHits(values)
            })
            .catch((err) => {})

        const onScroll = () => {
            setOffset(window.scrollY)
        }

        window.addEventListener('scroll', onScroll, { passive: true })
        return () => {
            isMount = false
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    useEffect(() => {
        if (searchQuery === '') setSearchResults(null)

        if (searchQuery.length > 2) {
            productService.getProductsSearchQuery(searchQuery).then((data) => {
                if (data.values) setSearchResults(data.values)
            })
        }
    }, [searchQuery])

    useEffect(() => {
        if (offset < prevOffset) setShowLinks(true)
        else setShowLinks(false)

        setPrevOffset(offset)
    }, [offset])

    useEffect(() => {
        document.body.style.overflow = hamIsActive || searchIsActive ? 'hidden' : 'auto'
    }, [hamIsActive, searchIsActive])

    const location = useLocation()

    useEffect(() => {
        setHamIsActive(false)
        setSearchIsActive(false)
    }, [location.pathname])

    return (
        <>
            <header
                className={clsx(classes.header, {
                    [classes.tiny]: offset >= 50,
                    [classes.tinyWithLinks]: showLinks,
                    [classes.transparent]: transparent,
                    [classes.white]: hamIsActive || searchIsActive,
                })}>
                <div className={classes.inner}>
                    <div className={classes.menu}>
                        <button
                            className={classes.header__button}
                            onClick={() => setHamIsActive(!hamIsActive)}>
                            <img src={Menu} alt='Меню' />
                        </button>
                        <button
                            className={classes.header__button}
                            onClick={() => setSearchIsActive(!searchIsActive)}>
                            <img src={Search} alt='Поиск по товарам' />
                        </button>
                    </div>
                    <Link to='/' className={classes.logo}>
                        <div
                            className={classes.logoBg}
                            style={{ backgroundImage: `url('${Logo}')` }}
                        />
                    </Link>
                    <div className={classes.nav}>
                        <ProfileButton isAuthorized={isAuth} balance={balance} />
                    </div>
                </div>
                <div className={classes.innerLinks}>
                    {links.map((l, i) => (
                        <Link key={`row_link_${i}`} to={l.path}>
                            {l.name}
                        </Link>
                    ))}
                </div>
            </header>
            <aside className={clsx(aside.aside, { [aside.active]: hamIsActive })}>
                <nav role='navigation'>
                    <ul className={aside.links}>
                        {links.map((l, i) => (
                            <li key={`aside_link_${i}`}>
                                <Link to={l.path}>{l.name}</Link>
                            </li>
                        ))}
                        <li>
                            <a href='tel:+74952601003' className={classes.phoneBtn}>
                                Позвонить
                            </a>
                        </li>
                    </ul>
                </nav>
            </aside>
            <aside className={clsx(aside.search, { [aside.active]: searchIsActive })}>
                <div className={aside.empty} />
                <input
                    autoCorrect='off'
                    className={aside.input}
                    onChange={({ target: { value } }) => setSearchQuery(value)}
                    placeholder='Я ищу...'
                />
                <ul className={aside.results}>
                    {searchResults && searchResults.length > 0 ? (
                        searchResults.map((p) => (
                            <li key={p._id}>
                                <Link
                                    onClick={() => setSearchIsActive(false)}
                                    to={`/products/${p.slug}`}>
                                    {p.name}
                                </Link>
                            </li>
                        ))
                    ) : searchResults && searchResults.length === 0 ? (
                        <li>Ничего не найдено🥺</li>
                    ) : (
                        <>
                            <li>
                                <Link
                                    onClick={() => setSearchIsActive(false)}
                                    to={`/categories/aromaboksy`}>
                                    Аромабоксы
                                </Link>
                            </li>
                            <li>
                                <Link
                                    onClick={() => setSearchIsActive(false)}
                                    to={`/brands/tom-ford`}>
                                    Tom Ford
                                </Link>
                            </li>
                            <li>
                                <Link onClick={() => setSearchIsActive(false)} to={`/brands/dior`}>
                                    Dior
                                </Link>
                            </li>
                            <li>
                                <Link
                                    onClick={() => setSearchIsActive(false)}
                                    to={`/brands/chanel`}>
                                    Chanel
                                </Link>
                            </li>
                            <li>
                                <Link
                                    onClick={() => setSearchIsActive(false)}
                                    to={`/brands/by-kilian`}>
                                    BY KILIAN
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
                <div className={aside.recs}>
                    {searchResults && searchResults.length > 0 ? (
                        <ItemsGrid
                            items={searchResults}
                            filters={[]}
                            filterPrice={[]}
                            pickedBrands={[]}
                            fromSearch
                        />
                    ) : (
                        <ItemsGrid
                            items={hits}
                            filters={[]}
                            filterPrice={[]}
                            pickedBrands={[]}
                            fromSearch
                        />
                    )}
                </div>
            </aside>
        </>
    )
}

const links = [
    {
        path: '/categories/parfyumeriya',
        name: 'Парфюмерия',
    },
    {
        path: '/categories/diffuzory',
        name: 'Диффузоры',
    },
    {
        path: '/categories/aromaboksy',
        name: 'Аромабоксы',
    },
    {
        path: '/brands',
        name: 'Бренды',
    },
    {
        path: '/promo',
        name: 'Акции',
    },
    {
        path: '/contacts',
        name: 'Магазины',
    },
]

export default Header
