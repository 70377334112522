import { ICart } from "../interfaces/Cart";
import { IUserOrderCartProduct } from "../interfaces/Order";
import { getMinPrice } from "./getMinPrice";

export const getTotalDefaultPrice = (cart: ICart[]) => {
    let total = 0
    cart.forEach(c => {
        const { amount, modifiers, product } = c
        total += modifiers ? modifiers.priceDefault * amount : product.priceDefault * amount
    })
    return total
}

export const getSalesPrice = (cart: ICart[]) => {
    let total = 0
    cart.forEach(c => {
        const { modifiers, amount, product } = c
        total += modifiers ? (modifiers.priceSale === 0 ? 0 : modifiers.priceDefault - modifiers.priceSale) * amount : (product.priceSale === 0 ? 0 : product.priceDefault - product.priceSale) * amount
    })
    return total
}


export const getOrderPrice = (cart: IUserOrderCartProduct[], deliveryPrice: number) => {
    let total = deliveryPrice
    cart.forEach(c => {
        const { modifierArticle, quantity, product } = c
        if (product) {
            const modifier = product.modifiers.find(m => m.article === modifierArticle)
            if (modifier)
                total += modifierArticle && modifier ? (modifier.priceSale === 0 ? modifier.priceDefault : modifier.priceSale) * quantity : (product.priceSale === 0 ? product.priceDefault : product.priceSale) * quantity
        }
    })
    return total
}