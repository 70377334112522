import Page from '../Page/Page'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
// import { PatternFormat } from 'react-number-format'
import InputMask from 'react-input-mask'
import { useCookies } from 'react-cookie'
import classes from './Login.module.css'
import React, { useState, useRef, useEffect } from 'react'
import { DateTime } from 'ts-luxon'
import { LoginService } from '../../api/LoginService'
import { AxiosError } from 'axios'
import { CustomerService } from '../../api/CustomerService'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Helmet } from 'react-helmet-async'
import { PatternFormat } from 'react-number-format'

const getErrorMessage = (msg: string) => {
    switch (msg) {
        case 'code mismatch':
            return 'Неверный код'
        case 'phone must be a valid phone number':
            return 'Неверный формат телефона'
        case 'phone or code are mismatch':
            return 'Неверный номер телефона или смс-код'
        case 'customer are not registered':
            return 'Пользователь не зарегистрирован'
        case 'less than 1 minute has passed since the code was last sent':
            return 'Попробуйте запросить код через минуту'
        default:
            return 'Произошла ошибка, попробуйте позже'
    }
}

const Login = () => {
    const navigate = useNavigate()
    const loginService = new LoginService()

    const reqCodeBtn = useRef<HTMLButtonElement>(null)
    const verifyBtn = useRef<HTMLButtonElement>(null)
    const codeRef = useRef<HTMLInputElement>(null)
    // const resetCodeBtn = useRef<HTMLButtonElement>(null)
    const resetCodeBtn = useRef<HTMLDivElement>(null)

    const [cookies, setCookie] = useCookies(['token'])
    const [phone, setPhone] = useState('')
    const [code, setCode] = useState('')
    const [time, setTime] = useState(90)

    const errorRef = useRef<HTMLParagraphElement>(null)

    const { cart } = useTypedSelector((state) => state.cartReducer)

    useEffect(() => {
        if (cookies.token) {
            navigate('/account/profile')
        }
    }, [])

    useEffect(() => {
        if (errorRef.current) errorRef.current.innerText = ''
    }, [phone, code])

    const parsePhone = (phone: string) => {
        let p = phone.replace(/^\+|[\s_()-]/g, '')
        if (p.charAt(0) === '8') p = p.substring(0, 0) + '7' + p.substring(1)
        return p
    }
    const parseCode = (code: string) => code.replace(/_/g, '')

    const requestCode = async () => {
        try {
            if (reqCodeBtn.current) reqCodeBtn.current.disabled = true
            if (errorRef.current) errorRef.current.innerText = ''
            if (codeRef.current) codeRef.current.classList.remove('d-none')
            if (resetCodeBtn.current) {
                resetCodeBtn.current.classList.remove('d-none')
            }

            let a = time > 0 ? time : 90
            const t = setInterval(() => {
                if (a > 0) a -= 1
                else {
                    console.log('clear')
                    clearInterval(t)
                }
                setTime(a)
            }, 1000)

            // TODO: disable button, handle the error
            const codeReq = await loginService.login(parsePhone(phone))
            // TODO: if no error hide reqButton and show codeInput
            if (reqCodeBtn.current) reqCodeBtn.current.classList.add('d-none')

            const codeInput = document.body.querySelector(
                'input[name=one-time-code]'
            ) as HTMLInputElement
            if (codeInput) {
                codeInput.classList.remove('d-none')
                codeInput.focus()
            }

            if (verifyBtn.current) verifyBtn.current.classList.remove('d-none')
        } catch (e) {
            const error = e as AxiosError<{
                message: string | string[]
            }>
            if (error.response)
                if (errorRef.current)
                    errorRef.current.innerText = getErrorMessage(
                        typeof error.response.data.message === 'string'
                            ? error.response.data.message || ''
                            : error.response.data.message[0]
                    )
        } finally {
            if (reqCodeBtn.current) reqCodeBtn.current.disabled = false
        }
    }

    const verifyPhone = async () => {
        // TODO: disable button, handle error
        try {
            if (verifyBtn.current) verifyBtn.current.disabled = true
            if (errorRef.current) errorRef.current.innerText = ''
            const verifyReq = await loginService.login(parsePhone(phone), parseCode(code))
            const dt = DateTime.now().plus({ days: 7 })
            setCookie('token', verifyReq.token, {
                path: '/',
                secure: true,
                expires: dt.toJSDate(),
            })

            // SEND NO-AUTH USER CART TO THIS USER
            if (cart.length > 0) {
                const userService = new CustomerService()
                const res = await userService.sendCart(cart)
            }

            const query = window.location.search.split('=')
            if (query && query[0] === '?from') {
                navigate(`/${query[1]}`)
            } else {
                navigate('/account/profile')
            }
        } catch (e) {
            const error = e as AxiosError<{
                message: string | string[]
            }>
            if (error.response) {
                if (error.response.data.message === 'customer are not registered') {
                    navigate(`/signup?phone=${parsePhone(phone)}&code=${parseCode(code)}`)
                } else {
                    if (errorRef.current)
                        errorRef.current.innerText = getErrorMessage(
                            typeof error.response.data.message === 'string'
                                ? error.response.data.message || ''
                                : error.response.data.message[0]
                        )
                }
            }
        } finally {
            if (verifyBtn.current) verifyBtn.current.disabled = false
        }
    }

    const onPaste = (
        e: React.ClipboardEvent,
        setFunc: React.Dispatch<React.SetStateAction<string>>
    ) => {
        e.stopPropagation()
        e.preventDefault()

        const clipboardData = e.clipboardData
        if (clipboardData) {
            let pastedData = clipboardData.getData('Text')
            if (pastedData.charAt(0) === '8')
                pastedData = pastedData.substring(0, 0) + '+7' + pastedData.substring(1)
            setFunc(pastedData)
        }
    }
    const parseTime = (_time: number) => {
        if (_time < 0) return '00:00'
        const minute = Math.floor(_time / 60)
        const seconds = _time % 60
        let minStr = minute.toString()
        let secStr = seconds.toString()
        if (minute < 10) minStr = `0${minute}`

        if (seconds < 10) secStr = `0${seconds}`
        return `${minStr}:${secStr}`
    }

    return (
        <Page hideMiniCart>
            <Helmet prioritizeSeoTags>
                <title>Вход в личный кабинет</title>
                <link rel='canonical' href='https://parfumeratelier.ru/login' />
                <meta property='og:title' content={`Вход в личный кабинет`} />
                <meta property='og:url' content={`https://parfumeratelier.ru/login`} />
            </Helmet>
            <div className={classes.login}>
                {/*<h1 style={{marginTop: "4rem"}}>Нет аккаунта?</h1>*/}
                {/*<p>Можете зарегистрироваться</p>*/}

                {/*<Link to="/signup"*/}
                {/*      className='btn btn-uppercase'*/}
                {/*      style={{ marginTop: '1rem' }}>*/}
                {/*    Зарегистрироваться*/}
                {/*</Link>*/}
                {/*<div style={{marginBottom: "4rem"}}/>*/}
                <h1>войти</h1>
                <p>Мы отправим на номер SMS-сообщение с кодом подтверждения.</p>
                {/* @ts-ignore */}
                <InputMask
                    type='text'
                    onPaste={(e: React.ClipboardEvent) => onPaste(e, setPhone)}
                    mask='+7 (999) 999-99-99'
                    autoComplete='tel'
                    name='phone'
                    placeholder='Номер телефона'
                    className='input'
                    value={phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                />
                <PatternFormat
                    getInputRef={codeRef}
                    name='one-time-code'
                    type='text'
                    className='input d-none'
                    onChange={({ target: { value } }) => setCode(value)}
                    autoComplete='one-time-code'
                    inputMode='numeric'
                    format='#####'
                    value={code}
                    allowEmptyFormatting
                    placeholder='Код из СМС'
                    mask='_ '
                />
                {/* <input type='text' name='code' className='input' autoComplete='one-time-code' value={code} placeholder='Код из СМС' onChange={({ target: { value } }) => setCode(value)} /> */}
                <div className={classes.buttonWrapper}>
                    <button
                        ref={reqCodeBtn}
                        onClick={requestCode}
                        className='btn btn-uppercase'
                        disabled={parsePhone(phone).length !== 11}
                        style={{ marginTop: '1rem' }}>
                        Получить код
                    </button>

                    <button
                        ref={verifyBtn}
                        onClick={verifyPhone}
                        className='btn btn-uppercase d-none'
                        disabled={parseCode(code).length !== 5}
                        style={{ marginTop: '2rem' }}>
                        Подтвердить код
                    </button>
                </div>
                <div
                    ref={resetCodeBtn}
                    className={`${classes.buttonWrapper} d-none`}
                    style={{ marginTop: '2rem' }}>
                    <p style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                        {parseTime(time)}
                    </p>
                    <button onClick={requestCode} className='btn btn-uppercase' disabled={time > 0}>
                        Отправить код повторно
                    </button>
                </div>
                <p ref={errorRef} className='error' style={{ textAlign: 'center' }}></p>
            </div>
        </Page>
    )
}

export default Login
