import React, { Suspense } from 'react'
import { Await, useLoaderData, useNavigate } from 'react-router-dom'
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import { Loader } from '../../components/loader/Loader'
import { IUserOrder, IUserOrderCartProduct } from '../../interfaces/Order'
import classes from './Profile.module.css'
import { getOrderPrice } from '../../utils/getTotalPrice'

type PromiseType = {
    orders: Promise<IUserOrder[]>
}
const Orders = () => {
    const { orders } = useLoaderData() as PromiseType
    const nav = useNavigate()
    const getProductFullName = (cartItem: IUserOrderCartProduct) => {
        const modifier = cartItem.product.modifiers.find(
            (m) => m.article === cartItem.modifierArticle
        )
        if (modifier)
            return `${cartItem.product.name}, ${modifier.name}${
                cartItem.product.slug !== 'dostavka' ? ' мл' : ''
            }`
        return cartItem.product.name
    }
    return (
        <Suspense fallback={<Loader />}>
            <Await
                resolve={orders}
                errorElement={<ErrorElement msg='Не удалось загрузить заказы' />}>
                {(_orders: IUserOrder[]) => {
                    return (
                        <div>
                            <h2>Мои покупки</h2>
                            {_orders.map((order, idx) => {
                                return (
                                    <div
                                        key={`order_${idx}`}
                                        className={classes.order}
                                        onClick={() => nav(`/account/orders/${order.code}`)}>
                                        <div className={classes.orderHeader}>
                                            {/* header */}
                                            <span>{order.source.name}</span>
                                            <span>{order.deliveryType.name}</span>
                                        </div>
                                        {/* info */}
                                        <div className={classes.orderInfo}>
                                            <span className={classes.orderInfo_code}>
                                                {order.code}
                                            </span>
                                            <div>
                                                <span>
                                                    Оформлено:{' '}
                                                    {new Date(order.createdAt).toLocaleDateString()}
                                                </span>
                                                <span>
                                                    {order.cart.reduce(
                                                        (acc, item) => (acc += item.quantity),
                                                        0
                                                    )}{' '}
                                                    тов
                                                </span>
                                                <span>
                                                    {getOrderPrice(
                                                        order.cart,
                                                        order.deliveryType.price
                                                    )}{' '}
                                                    ₽
                                                </span>
                                                <span
                                                // className={
                                                //     order.isPayed
                                                //         ? classes.orderPayed
                                                //         : classes.orderNotPayed
                                                // }
                                                >
                                                    {/* {order.isPayed ? 'Оплачено' : 'Ожидает оплаты'} */}
                                                </span>
                                            </div>
                                        </div>
                                        {/* item list */}
                                        <ul className={classes.orderList}>
                                            {order.cart.map((item) => (
                                                <li key={item.productId}>
                                                    {item.product
                                                        ? getProductFullName(item)
                                                        : 'Не удалось получить информацию по товару'}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )
                            })}
                        </div>
                    )
                }}
            </Await>
        </Suspense>
    )
}

export default Orders
