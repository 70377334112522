import React, { Suspense, useCallback, useState } from 'react'
import Page from '../Page/Page'
import classes from './Contacts.module.css'
import { IRetailPoint } from '../../interfaces/RetailPoint'
import { Await, useLoaderData } from 'react-router-dom'
import { Loader } from '../../components/loader/Loader'
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import RetailPoint from './RetailPoint'
import NoRetailStore from '../../assets/images/no_retail_store.png'
import { Helmet } from 'react-helmet-async'
import Arrow from '../../assets/images/arrow_cat.svg'
type PromiseType = {
    points: Array<Promise<IRetailPoint>>
}
// _id: string
//     name: string
//     slug: string
//     imgUrl: string[]
//     externalId: string
//     externalCode: string
//     addressFull: {
//         city: string
//         street: string
//         house: string
//     }
//     address: string
//     image: string
const hardcodePoints: IRetailPoint[] = [
    {
        _id: 'o8rcd6t2',
        name: 'ТЦ «Терминал»',
        slug: 'tc-terminal',
        externalCode: '',
        externalId: '',
        addressFull: {
            city: 'Омск',
            street: 'пр-кт Мира',
            house: '9Б',
        },
        address: 'Напротив Ювелирторг',
        image: 'https://storage.yandexcloud.net/parfum.public/media/IMG_7954.JPG',
        imgUrl: [],
    },
    {
        _id: 'Iskitim',
        name: '',
        slug: 'iskitim-pushkina',
        externalCode: '',
        externalId: '',
        addressFull: {
            city: 'Искитим',
            street: 'ул. Пушкина',
            house: '81а/3',
        },
        address: '1 этаж, вход в магазин "Пятерочка"',
        image: 'https://storage.yandexcloud.net/parfum.public/media/IMG_7957.JPG',
        imgUrl: [],
    },
    {
        _id: 'kaz1',
        name: 'ТЦ «Park House»',
        slug: 'tc-park-house',
        externalCode: '',
        externalId: '',
        addressFull: {
            city: 'Казань',
            street: 'ул. Мусина',
            house: '33',
        },
        address: '1 этаж',
        image: 'https://storage.yandexcloud.net/parfum.public/media/IMG_6932.jpg',
        imgUrl: [],
    },
    {
        _id: 'kaz2',
        name: 'ТРК «Кольцо»',
        slug: 'trk-koltso',
        externalCode: '',
        externalId: '',
        addressFull: {
            city: 'Казань',
            street: 'ул. Петербургская',
            house: '1',
        },
        address: '1 этаж',
        image: 'https://storage.yandexcloud.net/parfum.public/media/IMG_0193.jpg',
        imgUrl: [],
    },
    {
        _id: 'Omsk',
        name: 'ТЦ "Континент 1"',
        slug: 'omsk-kontinent',
        externalCode: '',
        externalId: '',
        addressFull: {
            city: 'Омск',
            street: 'ул. 70 лет Октября',
            house: '25 к1',
        },
        address: 'Рядом с "Мегафон"',
        image: 'https://storage.yandexcloud.net/parfum.public/media/ge5sdyu.jpeg',
        imgUrl: [],
    },
]

const ORDER = [
    '6453766dd3a9d2f3d9240236',
    '63a03bf2913299ee27af0b8b',
    '63a03a7e913299ee27af0b88',
    '63a03ba5913299ee27af0b8a',
    '63a03b6d913299ee27af0b89',
    '6601596ac8964f147d38fce7',
    '66015a11c8964f147d38fce8',
    '6645c980270cb3c839e52e2c',
    '66e7fea29cbbc52c67a2a748',
    '658947b0b9e4553e34c604c6',
    '63a03cb4913299ee27af0b8e',
    '6448f6225aec6e14232a2e79',
    '63a03ce9913299ee27af0b8f',
    '6454efc2d9c58b9a1498a9f9',
    '63a03d55913299ee27af0b91',
    '666c1b7378314ed14184eecb',
    '63a03c5f913299ee27af0b8d',
    '63a03c22913299ee27af0b8c',
    '64b8e148a5a5c09ada757f54',
    'kaz1',
    'kaz2',
    '6576d0a1d2c71852e67ae988',
    '666c1ace78314ed14184eeca',
    '66f530294352843b70c1459c',
    '66015a7bc8964f147d38fce9',
    '66e7fcd59cbbc52c67a2a747',
    '63a03d1c913299ee27af0b90',
    '645374ebd3a9d2f3d9240235',
    '65363d31bb24c6a8af72b4fb',
    '65894fb8e8411f8c81cd8d9c',
    'o8rcd6t2',
    'Omsk',
    'Iskitim',
]

const Contacts = () => {
    const { points } = useLoaderData() as PromiseType
    const [activeCity, setActiveCity] = useState('Все города')
    const getCitiesList = (points: IRetailPoint[]) => {
        return points.reduce(
            (acc: string[], p) =>
                acc.includes(p.addressFull.city) ? acc : [...acc, p.addressFull.city],
            ['Все города']
        )
    }

    const sortedPoints = useCallback(
        (_points: IRetailPoint[]) => {
            const all = [..._points, ...hardcodePoints]
            return ORDER.map((o) => all.find((p) => p._id === o)!)
        },
        [points]
    )
    return (
        <Page>
            <Helmet prioritizeSeoTags>
                <title>Адреса наших магазинов</title>
                <link rel='canonical' href='https://parfumeratelier.ru/contacts' />
                <meta
                    name='description'
                    content='Адреса и телефоны магазинов аромабоксов и парфюмерии Parfumer Atelier в разных городах России.'
                />
                <meta property='og:title' content='Адреса наших магазинов' />
                <meta property='og:url' content='https://parfumeratelier.ru/contacts' />
                <meta
                    property='og:description'
                    content='Адреса и телефоны магазинов аромабоксов и парфюмерии Parfumer Atelier в разных городах России.'
                />
            </Helmet>
            <div className={classes.contacts}>
                <p className={classes.Title}>
                    Федеральная сеть PARFUMER ATELIER - это проект авторской парфюмерии, созданный
                    совместно с ведущими производителями парфюмерии со всего мира
                </p>
                <div className={classes.header} style={{ marginBottom: '3rem' }}>
                    <div className={classes.empty}>
                        <h2>Города</h2>
                        <Suspense fallback={<Loader />}>
                            <Await
                                resolve={points}
                                errorElement={
                                    <ErrorElement msg='Не удалось загрузить точки продаж' />
                                }>
                                {(points: IRetailPoint[]) => {
                                    const cities = getCitiesList(sortedPoints(points))
                                    return (
                                        <div className={'select-box ' + classes.selectBox}>
                                            <div className='select-box__current' tabIndex={1}>
                                                {cities.map((c) => (
                                                    <div className='select-box__value' key={c}>
                                                        <input
                                                            className='select-box__input'
                                                            type='radio'
                                                            id={c}
                                                            value={c}
                                                            onChange={() => setActiveCity(c)}
                                                            name='deliveryType'
                                                            checked={c === activeCity}
                                                        />
                                                        <p className='select-box__input-text'>
                                                            {c}
                                                        </p>
                                                    </div>
                                                ))}
                                                <img
                                                    className='select-box__icon'
                                                    src={Arrow}
                                                    alt='Arrow Icon'
                                                    aria-hidden='true'
                                                />
                                            </div>
                                            <ul className='select-box__list'>
                                                {cities.map((dt) => (
                                                    <li key={`li_${dt}`}>
                                                        <label
                                                            className='select-box__option'
                                                            htmlFor={dt}
                                                            aria-hidden='true'>
                                                            {dt}
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )
                                }}
                            </Await>
                        </Suspense>
                    </div>

                    <div className={classes.block}>
                        <h2>Контакты</h2>
                        <p>для связи с нами Вы можете воспользоваться следующими способами</p>

                        <div className={classes.phones}>
                            <a href='tel:+74952601003' className={classes.phone}>
                                +7 (495) 260-10-03
                            </a>

                            <a
                                href='https://wa.me/message/M57AZ5K4Y4NRE1'
                                className={classes.wa}
                                target='_blank'
                                rel='noopener noreferrer nofollow'>
                                Написать в WhatsApp
                            </a>
                        </div>
                    </div>
                    <div />
                </div>

                <Suspense fallback={<Loader />}>
                    <Await
                        resolve={points}
                        errorElement={<ErrorElement msg='Не удалось загрузить точки продаж' />}>
                        {(points: IRetailPoint[]) => {
                            const _points = sortedPoints(points)
                            return (
                                <>
                                    {_points.map((p, i) => {
                                        if (
                                            activeCity === 'Все города' ||
                                            activeCity === p.addressFull.city
                                        )
                                            return (
                                                <RetailPoint
                                                    key={p._id}
                                                    address={{
                                                        city: p.addressFull.city,
                                                        street: p.addressFull.street,
                                                        house: p.addressFull.house,
                                                        notice: p.address,
                                                    }}
                                                    _id={p._id}
                                                    name={p.name}
                                                    image={
                                                        p.image === '' || !p.image
                                                            ? NoRetailStore
                                                            : p.image
                                                    }
                                                    direction={i % 2}
                                                />
                                            )
                                        else return null
                                    })}
                                </>
                            )
                        }}
                    </Await>
                </Suspense>
            </div>
        </Page>
    )
}

export default Contacts
